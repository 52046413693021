import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import PageBlockContact from "../../components/page-blocks/page-block-contact";
import PageBlockDynamic from "../../components/page-blocks/page-block-dynamic/pageBlockDynamic";
import PageBlockHeader from "../../components/page-blocks/page-block-header";
import PageBlockParagraphwithsidebar from "../../components/page-blocks/page-block-paragraphwithsidebar";
import PageBlockTextGallery from "../../components/page-blocks/page-block-textgallery";
import SeoHeaders from "../../components/seo/seo-headers";
import GlobalContext from "../../contexts/global-provider";
import { websiteName } from "../../settings";
import Layout from "../layout";

const SailingAreaWildcard = ({
  pageContext: { page, sailingArea, cmsProducts, navigation, cruiseTypes, privacy },
}) => {
  
  const {setNavigation, setPrivacy, theme} = useContext(GlobalContext);
  setNavigation(navigation);
  setPrivacy(privacy);

  const data = {
    content: {
      general: {
        title: `Cruises naar ${sailingArea?.name}`,
        isSailingAreas: true,
        max: 9,
        sailingArea: sailingArea,
      },
      paragraph: {
        content: {
          paragraph: {
            text: sailingArea?.content?.paragraph?.text,
          },
          sidebar: {
            text: sailingArea?.content?.paragraph?.sidebarText,
          },
          layout: {
            paragraphPositionLeft: sailingArea?.content?.paragraph?.paragraphPositionLeft,
          },
        },
      },
      textGallery: {
        content: {
          general: {
            title: sailingArea?.content?.textGallery?.title,
            text: sailingArea?.content?.textGallery?.text,
          },
          gallery: {
            imageList: sailingArea?.content?.textGallery?.imageList,
          },
        },
      },
      contact: {
        content: {
          general: {
            title: sailingArea?.content?.contact?.title,
            sendTo: sailingArea?.content?.contact?.sendTo,
            conformationEmailTemplate: sailingArea?.content?.contact?.conformationEmailTemplate_Thalassa,
          },
        },
      },
    },
  };
  if (theme === "select-together") {
    data.content.contact.content.general.conformationEmailTemplate =
      sailingArea?.content?.contact?.conformationEmailTemplate_SelectTogether;
  }
  if (!sailingArea) {
    return <></>;
  }

  // Output

  return (
    <Layout
      isTiny={true}
      hasHeader={true}
      showQsm={Boolean(page.content.layout.qsm)}
      isTinted={Boolean(page.content.layout.backgroundTinted)}
      cruiseTypes={cruiseTypes}
    >
      <SeoHeaders
        seo={sailingArea.content.seo}
        pageTitle={
          sailingArea.content.seo && sailingArea.content.seo.title
            ? sailingArea.content.seo.title
            : page.content.general.title
        }
        pagePath={page.content.general.path}
        siteName={websiteName[theme]}
      />
      <Helmet>
        <title>
          {sailingArea.content.seo && sailingArea.content.seo.title
            ? sailingArea.content.seo.title
            : page.content.general.title}
        </title>
      </Helmet>
      <PageBlockHeader data={sailingArea} />
      <PageBlockParagraphwithsidebar data={data.content.paragraph} />
      <PageBlockDynamic theme={theme} data={data} cmsProducts={cmsProducts} />
      <PageBlockTextGallery data={data.content.textGallery} />
      {/* <PageBlockImagesidetext data={data.content.textGallery} /> */}

      <PageBlockContact data={data.content.contact} privacy={privacy} theme={theme} />
    </Layout>
  );
};

export default SailingAreaWildcard;
